
// import { reactive } from 'vue'
import { Vue } from 'vue-class-component';
// import ImageContainer from '../components/ImageContainer.vue';
// @Options({
//  picked: String
// })
export default class QuestionOne extends Vue {
  private picked = '';

  get label() {
    return this.picked;
  }
  data() {
    return {
      picked: '',
    };
  }
  toggleSelection() {
    console.log('Hello World!', this.picked);
    localStorage.setItem('questionOne', this.picked);
  }
}
