<template>
    <div
        id="page6"
        class="pages"
    >
        <div class="section-header">
            <h2>您计划在会奖旅行期间在哪住宿?</h2>
        </div>
        <div class="question-list">
            <!-- <div class="question-list-item"><router-link to="/question-six">A: 国际连锁酒店</router-link></div>
            <div class="question-list-item"><router-link to="/question-six">B: 精品旅馆</router-link></div>
            <div class="question-list-item"><router-link to="/question-six">C: 酒店式公寓</router-link></div>
            <div class="question-list-item"><router-link to="/question-six">D: 经济型酒店</router-link></div> -->
            <div class="question-list-item">
                <input
                    type="radio"
                    id="A"
                    value="国际连锁酒店"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="A">A: 国际连锁酒店<span v-if="picked  === '国际连锁酒店'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="B"
                    value="精品旅馆"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="B">B: 精品旅馆 <span v-if="picked  === '精品旅馆'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="C"
                    value="酒店式公寓"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="C">C: 酒店式公寓 <span v-if="picked  === '酒店式公寓'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="D"
                    value="经济型酒店"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="D">D: 经济型酒店 <span v-if="picked  === '经济型酒店'">✓</span></label>
            </div>
            <div class="q-next-page">
                <router-link to="/question-four">上一题</router-link>
                <router-link
                    to="/question-six"
                    v-if="picked"
                >下一题</router-link>
            </div>
        </div>

    </div>
</template>
   
<script lang="ts">
import { Vue } from 'vue-class-component';
// import ImageContainer from '../components/ImageContainer.vue';
// @Options({
//   components: {
//     ImageContainer,
//   },
// })
export default class QuestionFive extends Vue {
  private picked = '';

  get label() {
    return this.picked;
  }
  data() {
    return {
      picked: '',
    };
  }
  toggleSelection() {
    console.log('Hello World!', this.picked);
    localStorage.setItem('questionFive', this.picked);
  }
}
</script>
<style>
#page6 {
  background-image: url('./../assets/bg_image_5.png');
}
#page6 .question-list {
  width: 70vw;
}
</style>

