
// import { useRoute } from 'vue-router';
import { Options, Vue } from 'vue-class-component';
// import { computed } from 'vue';
import ImageContainer from '../components/ImageContainer.vue';
// import { getQueryByName } from './../utils';
@Options({
  components: {
    ImageContainer,
  },
})
export default class Cutomize extends Vue {
  data() {
    // const id = getQueryByName('selected');
    const id = localStorage.getItem('questionFour');

    console.log('ssss', id);
    return {
      selectedMap: id,
    };
  }
}
