<template>
    <div
        id="page3"
        class="pages"
    >
        <div class="section-header">
            <h2>您计划在哪个时间安排会奖旅行?</h2>
        </div>
        <div class="question-list">
            <!-- <div class="question-list-item"><router-link to="/question-three">A:  12月 ~ 2月</router-link></div>
            <div class="question-list-item"><router-link to="/question-three">B:  3月 ~ 5月</router-link></div>
            <div class="question-list-item"><router-link to="/question-three">C:  6月 ~ 8月</router-link></div>
            <div class="question-list-item"><router-link to="/question-three">D:  9月 ~ 11月</router-link></div> -->
            <div class="question-list-item">
                <input
                    type="radio"
                    id="A"
                    value="12月 ~ 2月"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="A">A: 12月 ~ 2月<span v-if="picked  === '12月 ~ 2月'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="B"
                    value="3月 ~ 5月"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="B">B: 3月 ~ 5月 <span v-if="picked  === '3月 ~ 5月'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="C"
                    value="6月 ~ 8月"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="C">C: 6月 ~ 8月 <span v-if="picked  === '6月 ~ 8月'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="D"
                    value="9月 ~ 11月"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="D">D: 9月 ~ 11月 <span v-if="picked  === '9月 ~ 11月'">✓</span></label>
            </div>
            <div class="q-next-page">
                <router-link to="/question-one">上一题</router-link>
                <router-link
                    to="/question-three"
                    v-if="picked"
                >下一题</router-link>
            </div>
        </div>

    </div>
</template>
   
<script lang="ts">
import { Vue } from 'vue-class-component';
// import ImageContainer from '../components/ImageContainer.vue';
// @Options({
//   components: {
//     ImageContainer,
//   },
// })

export default class QuestionTwo extends Vue {
  private picked = '';

  get label() {
    return this.picked;
  }
  data() {
    return {
      picked: '',
    };
  }
  toggleSelection() {
    console.log('Hello World!', this.picked);
    localStorage.setItem('questionTwo', this.picked);
  }
}
</script>
<style>
#page3 {
  background-image: url('./../assets/bg_image_2.png');
}
</style>

