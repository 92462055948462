<template>
  <router-view />
</template>
<style module>
@font-face {
    font-family: "ZiZhiQuXiMaiTi";
    src: url("assets/input.eot"); /* IE9 */
    src: url("assets/input.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("assets/input.woff") format("woff"), /* chrome, firefox */
    url("assets/input.ttf") format("truetype"), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
    url("assets/input.svg#ZiZhiQuXiMaiTi") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: normal;
}
html,
body {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    margin: 0;
}
</style>
