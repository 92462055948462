import { createWebHistory, createRouter } from "vue-router";
import Home from "@/pages/home.vue";
import QuestionTwo from "@/pages/questionTwo.vue";
import QuestionOne from "@/pages/questionOne.vue";
import QuestionThree from "@/pages/questionThree.vue"
import QuestionFour from "@/pages/questionFour.vue"
import QuestionFive from "@/pages/questionFive.vue"
import QuestionSix from "@/pages/questionSix.vue"
import Cutomize from "@/pages/cutomize.vue"
import SaveResult from "@/pages/saveResult"
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/question-two",
    name: "QuestionTwo",
    component: QuestionTwo,
  },
  {
    path: "/question-one",
    name: "QuestionOne",
    component: QuestionOne,
  },
  {
    path: "/question-three",
    name: "QuestionThree",
    component: QuestionThree,
  },
  {
    path: "/question-four",
    name: "QuestionFour",
    component: QuestionFour,
  },
  {
    path: "/question-five",
    name: "QuestionFive",
    component: QuestionFive
  },
  {
    path: "/question-six",
    name: "QuestionSix",
    component: QuestionSix
  },
  {
    path: "/cutomize",
    name: "Cutomize",
    component: Cutomize
  }, {
    path: "/saveResult",
    name: "SaveResult",
    component: SaveResult
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
