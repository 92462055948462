<template>
    <div
        id="page5"
        class="pages"
    >
        <div class="section-header">
            <h2>您喜欢以下哪种类型旅行活动?</h2>
        </div>
        <div class="question-list list-higher">
            <!-- <div class="question-list-item"><router-link to="/question-five">A: 休闲观光</router-link></div>
            <div class="question-list-item"><router-link to="/question-five">B: 文化参观</router-link></div>
            <div class="question-list-item"><router-link to="/question-five">C: 美食购物</router-link></div>
            <div class="question-list-item higher"><router-link to="/question-five">D: 去城市周边探索自然及野生动物</router-link></div> -->
            <div class="question-list-item">
                <input
                    type="radio"
                    id="A"
                    value="休闲观光"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="A">A: 休闲观光<span v-if="picked  === '休闲观光'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="B"
                    value="文化参观"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="B">B: 文化参观 <span v-if="picked  === '文化参观'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="C"
                    value="美食购物"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="C">C: 美食购物 <span v-if="picked  === '美食购物'">✓</span></label>
            </div>
            <div class="question-list-item higher">
                <input
                    type="radio"
                    id="D"
                    value="去城市周边探索自然及野生动物"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="D">D: 去城市周边探索自然及野生动物 <span v-if="picked  === '去城市周边探索自然及野生动物'">✓</span></label>
            </div>
            <div class="q-next-page">
                <router-link to="/question-three">上一题</router-link>
                <router-link
                    to="/question-five"
                    v-if="picked"
                >下一题</router-link>
            </div>
        </div>

    </div>
</template>
   
<script lang="ts">
import { Vue } from 'vue-class-component';
// import ImageContainer from '../components/ImageContainer.vue';
// @Options({
//   components: {
//     ImageContainer,
//   },
// })
export default class QuestionFour extends Vue {
  private picked = '';

  get label() {
    return this.picked;
  }
  data() {
    return {
      picked: '',
    };
  }
  toggleSelection() {
    console.log('Hello World!', this.picked);
    localStorage.setItem('questionFour', this.picked);
  }
}
</script>
<style>
#page5 {
  background-image: url('./../assets/bg_image_4.png');
  /* background-position-y: -40px; */
  background-size: cover;
}
.list-higher {
  margin-top: 25vh;
  width: 71vw;
}
.higher {
  height: 90px;
  background-size: 100% 103%;
  background-position-y: -2px;
}
</style>

