<template>
  <div
    id="page1"
    class="pages"
    v-on:click="toNext"
  >
    <!-- <HelloWorld msg="在下一次会奖旅行中，您预计会有几个人一同出行" /> -->
    <div class="section-header header-1">
      <h1>测一测</h1>
      <div class="header-3">
        <div>最适合你的会奖旅行</div>
        <strong>目的地 </strong>是哪里
      </div>

    </div>
    <div class="next-page">
      <router-link to="/question-one">点击图片开始测试</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import vhCheck from 'vh-check';
vhCheck('browser-address-bar');
console.log('ss', window.innerHeight);
if (document.documentElement.clientWidth > 766) {
  alert('为了更好的效果，请在手机端打开')
}

// import HelloWorld from "../components/HelloWorld.vue";
// @Options({
//     components: {
//         HelloWorld,
//     },
// })
export default class Home extends Vue {
    toNext() {
      this.$router.push({path:'/question-one'})
  }
}
</script>

<style>
.pages {
  font-family: 'ZiZhiQuXiMaiTi';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  width: 100vw;
  height: calc(100vh - var(--browser-address-bar, 0px));
  /* width: 100%; */
  /* height: 100%; */
  /* background-size: cover; */
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
#page1 {
  background-image: url('./../assets/bg_image_home.png');
}
.section-header {
  padding-top: 2vh;
  width: 72vw;
  margin: 0 auto;
}
.header-1 h1 {
  font-size: 64px;
  line-height: 1;
  margin: 10px auto;
}
.header-3 {
  font-size: 28px;
}
.header-3 strong {
  font-size: 45px;
}
.header-1 h2,
.header-3 {
  animation: scrollUp-desc 0.8s linear 0.3s 1 both;
}
/* .section-header h3 {
  animation: scrollUp 0.4s linear 0.2s 1 both;
} */
.section-header strong {
  font-size: 30px;
}
.next-page {
  bottom: 7vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  animation: 0.8s linear 1s infinite alternate shining;
}
.next-page a {
  color: #2c3e50;
  font-size: 25px;
}
.next-page button {
  color: #2c3e50;
  font-size: 25px;
}
@keyframes slideUp {
  0% {
    opacity: 0;
    bottom: -10vh;
  }
  30% {
    opacity: 0.3;
    bottom: -5vh;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
@keyframes scrollUp {
  0% {
    opacity: 0;
    transform: translateY(10vh);
  }
  30% {
    opacity: 0.3;
    transform: translateY(5vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0vh);
  }
}
@keyframes scrollUp-desc {
  0% {
    opacity: 0;
    transform: translateY(10vh);
  }
  30% {
    opacity: 0.3;
    transform: translateY(5vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0vh);
  }
}
@keyframes shining {
  0% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  50% {
    opacity: 1;
    transform: translateX(-50%) scale(1.2);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}
@keyframes shining-tag {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
