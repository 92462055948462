
import { Vue } from 'vue-class-component';
import { NetWork } from '../api/network';
// import ImageContainer from '../components/ImageContainer.vue';
// @Options({
//   components: {
//     ImageContainer,
//   },
// })
export default class QuestionSix extends Vue {
  private picked = '';

  get label() {
    return this.picked;
  }
  data() {
    return {
      picked: '',
    };
  }
  toNext() {
    let answerList = []
    answerList[0] = localStorage.getItem('questionOne')
    answerList[1] = localStorage.getItem('questionTwo')
    answerList[2] = localStorage.getItem('questionThree')
    answerList[3] = localStorage.getItem('questionFour')
    answerList[4] = localStorage.getItem('questionFive')
    answerList[5] = localStorage.getItem('questionSix')
    console.log('没进来2？')
    console.log(localStorage.getItem('questionOne'));
    console.log(localStorage.getItem('questionTwo'));
    console.log(localStorage.getItem('questionThree'));
    console.log(localStorage.getItem('questionFour'));
    console.log(localStorage.getItem('questionFive'));
    console.log(localStorage.getItem('questionSix'));
    console.log('没进来1？')
    NetWork({
      answerList: answerList
    }, 'SYS9990')
        .then(() => {
          this.$router.push({path:'/cutomize'})
        })
  }
  toggleSelection() {
    console.log('Hello World!', this.picked);
    localStorage.setItem('questionSix', this.picked);
  }
}
