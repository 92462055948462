<template>
    <div
        id="page7"
        class="pages"
    >
        <div class="section-header">
            <h2>哪种美食体验更吸引您?</h2>
        </div>
        <div class="question-list list-wider">
            <!-- <div class="question-list-item"><router-link to="/cutomize?selected=A">A: 在高档餐厅就餐</router-link></div>
            <div class="question-list-item"><router-link to="/cutomize?selected=B">B: 吃遍本地美食</router-link></div>
            <div class="question-list-item"><router-link to="/cutomize?selected=C">C: 参加精品料理课程</router-link></div>
            <div class="question-list-item"><router-link to="/cutomize?selected=D">D: 去远郊品鉴乡村特色</router-link></div> -->
            <div class="question-list-item">
                <input
                    type="radio"
                    id="A"
                    value="在高档餐厅就餐"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="A">A: 在高档餐厅就餐<span v-if="picked  === '在高档餐厅就餐'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="B"
                    value="吃遍本地美食"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="B">B: 吃遍本地美食 <span v-if="picked  === '吃遍本地美食'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="C"
                    value="参加精品料理课程"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="C">C: 参加精品料理课程 <span v-if="picked  === '参加精品料理课程'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="D"
                    value="去远郊品鉴乡村特色"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="D">D: 去远郊品鉴乡村特色 <span v-if="picked  === '去远郊品鉴乡村特色'">✓</span></label>
            </div>
            <div class="q-next-page">
                <router-link to="/question-five">上一题</router-link>
              <button
                  @click="toNext"
                  v-if="picked"
              >查看定制路线</button>
            </div>
        </div>

    </div>
</template>
   
<script lang="ts">
import { Vue } from 'vue-class-component';
import { NetWork } from '../api/network';
// import ImageContainer from '../components/ImageContainer.vue';
// @Options({
//   components: {
//     ImageContainer,
//   },
// })
export default class QuestionSix extends Vue {
  private picked = '';

  get label() {
    return this.picked;
  }
  data() {
    return {
      picked: '',
    };
  }
  toNext() {
    let answerList = []
    answerList[0] = localStorage.getItem('questionOne')
    answerList[1] = localStorage.getItem('questionTwo')
    answerList[2] = localStorage.getItem('questionThree')
    answerList[3] = localStorage.getItem('questionFour')
    answerList[4] = localStorage.getItem('questionFive')
    answerList[5] = localStorage.getItem('questionSix')
    console.log('没进来2？')
    console.log(localStorage.getItem('questionOne'));
    console.log(localStorage.getItem('questionTwo'));
    console.log(localStorage.getItem('questionThree'));
    console.log(localStorage.getItem('questionFour'));
    console.log(localStorage.getItem('questionFive'));
    console.log(localStorage.getItem('questionSix'));
    console.log('没进来1？')
    NetWork({
      answerList: answerList
    }, 'SYS9990')
        .then(() => {
          this.$router.push({path:'/cutomize'})
        })
  }
  toggleSelection() {
    console.log('Hello World!', this.picked);
    localStorage.setItem('questionSix', this.picked);
  }
}
</script>
<style>
#page7 .list-wider {
  width: 80vw;
  margin-top: 30vh;
}
#page7 .higher {
  height: 69px;
  line-height: 1.2;
  background-size: 100% 103%;
  background-position-y: -2px;
}
#page7 {
  background-image: url('./../assets/bg_image_6.png');
}
#page7 .section-header {
  width: 80vw;
}
#page7 .question-list-item {
  padding-left: 0px;
  font-size: 28px;
}
#page7 .q-next-page a {
  font-size: 25px;
  margin-right: 0px;
}
 #page7 .q-next-page button {
   color: #2c3e50;
   display: inline-block;
   padding: 0 20px;
   text-align: center;
   line-height: 11.467vw;
   margin-bottom: 5.333vw;
   height: 11.467vw;
   /* margin-right: 6.667vw; */
   min-width: 20vw;
   background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVAAAAClCAYAAAAKwKXHAAAE6ElEQVR4nO3by24b5x3G4fcbjk6WD3WaIAjqbtI76P3fR5EsAzgI0KBNY7eWaJLThd1FgEi0XkumKj8PwN3Mh79AzI9z0liWZUpynGROMnK94/cfDuMoyfNDD/GA/Zrk4tBDPBBvkmwPPcQdWidZz0nOk823yfTnJCfX77P7QzKe592BzCe1JBknyfjq0JM8UEuy/DPJfw49yMMwfkzy+tBT3I1pm2y+T+bv5iRnyfSXZPw1yePrd1w9SpYnSVafYEp+YyTJlL0/cvTGN3nYZ02f0Pg2yb8PPcXdWNbJeJPk+zm5OEqOv0jyIhnP9uw5kiGeh7XvNgs9t6duzXKSjN2hp7gjF8nqaZLpf/c9p2TMcWnO582P060ZqzzcK9Vt3v9t04EHAfi/JaAAJQEFKM0fttny/gPwORm57tb4BwR0lyWX2Xq7A/iMTBmZcpR3z9Z/P6J7Arpkvfk1v7x5mYv1L9ktD/WtBIDfWk1znp69yJPTbzKN30/l3oBebl7nH69+yL/evMx2t7mDMQHun6P5LGOc5vzk60xXXMXvvYRfll22u3Xebi+z27297RkB7qUxpux2m1z3/MdTeICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAApXnfBmOsslqd5Gh+lN3u7aeYCeDgjuazTKs5I+PKbfYEdMrRfJ7nj/+Us+NH2S7bWx4R4H6ap+M8Of1jxrj6Qn3vGejx6jzPzl7k8cmXWbLc6oAA99UYqxytzjPG6spt9gZ0GnNO5mfJ8vRWhwO498b4mEv4JHm/wNVrAHyWPIUHKAkoQElAAUpzkl2St0kuk1zcwprHccf0PvFd3C2vpvQuDz1A6SLJJknm5HSd5Kdk912ynH/82uPrJI/iwL0v5mQ5zQc9MKRwkYzLCOkNLdtkeZmMzaEnubllk4yfk9VuTvI6yd+S5e/J5hYOsvFVMp1HQO+LVbJ7nkxf+EruwrJONi+TlX/Tu5HtNslPSdaHnuTm5l2y+jHJdizLMvLu7OTqt0VvuHryapU8uaXl+DivRrKak2lOTg89zEO0JK8vk8fOQG9mSX7eJF/uDj1IaTPG2PwXiCaZtFOTD2sAAAAASUVORK5CYII=)
   no-repeat;
   background-size: 100% 100%;
   font-size: 29px;
   text-decoration: none;
   font-size: 25px;
   margin-right: 0px;
   font-weight: bold;
   border: none;
 }
#page7 input {
  display: none;
}
/* #page6 .section-header h2{
        font-size: 31px;
    } */
</style>

