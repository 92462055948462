<template>
  <div>
    <h2
      v-if="loading"
      class="loading-info"
    >正在加载中...</h2>
    <div
      id="page9"
      class="pages hidden"
    >
      <div
        class="section-header"
        id="trip-info"
      >
        <h2>您的会奖旅行详情</h2>
        <p class="info-item">
          您的旅行将有<a>{{
            picQuestionOne
            }}</a>同行, {{picQuestionOneAnswer}}
        </p>
        <p>
          您预计在<a>{{
            picQuestionTwo
            }}</a>出行，{{picQuestionTwoAnswer}}
        </p>
        <p>
          您的行程是<a>{{
            picQuestionThree
            }}</a>，{{picQuestionThreeAnswer}}
        </p>
        <p>
          <a>{{
            picQuestionSix
            }}</a>最吸引您。{{picQuestionFiveAnswer}}
        </p>
        <p>
          <a>{{
            picQuestionFive
            }}</a>{{picQuestionFourAnswer}}
        </p>
        <h1></h1>
      </div>
      <img
        class="map-img"
        loading="eager"
        onload="monitorLoading"
        v-bind:src="require('../assets/'+ mapId + '.png')"
      />
      <div id="contact-info-section">
        <p>如果您有兴趣将墨尔本作为您的商务会奖旅行目的地，请联系我们！</p>
        <div class="contact-info">
          <p>江佩书 Natascha Chiang</p>
          <p class="samller">北亚及大中华地区总监</p>
          <p>natascha.chiang@melbournecb.com.au</p>
          <p>+86 21 5569 7757</p>
        </div>
        <p>关注墨尔本会议局，获得更多会奖旅行详情</p>
        <p></p>
        <img
          class="qr-code-img"
          v-bind:src="require('../assets/WechatIMG264.jpeg')"
        />
        <p
          v-on:click="createThumbnail"
          class="center"
        >长按图片保存您的会奖旅行推荐路线图</p>
      </div>
    </div>
  </div>

</template>

<script>
import { Vue } from 'vue-class-component';
// import ImageContainer from '../components/ImageContainer.vue';
// @Options({
//   components: {
//     ImageContainer,
//   },
// })
import html2canvas from 'html2canvas';

const picQuestionOneAnswer = () => {
  switch (localStorage.getItem('questionOne')) {
    case '20 ~ 50':
      return '行程可以根据您的喜好和要求进行高度私人定制，带您体验与众不同的墨尔本路线，了解景点背后的故事，体验许多当地人也不曾体验过的墨尔本独特面！';
    case '5 ~ 20':
      return '行程可以根据您的喜好和要求进行高度私人定制，带您体验与众不同的墨尔本路线，了解景点背后的故事，体验许多当地人也不曾体验过的墨尔本独特面！';
    default:
      return '墨尔本有许多适合举办大型活动或晚宴的地方，您可以自行制定主题活动，例如长桌午餐会、小巷派对等。';
  }
};
const picQuestionTwoAnswer = () => {
  switch (localStorage.getItem('questionTwo')) {
    case '12月 ~ 2月':
      return '这时的墨尔本是夏季，白天和夜晚温差较大，建议随身带一件轻薄外套。澳大利亚网球公开赛在每年的1月至2月举办，我们将协助您对接当地各项活动服务商。';
    case '3月 ~ 5月':
      return '这时的墨尔本是秋季，白天温度适中，早晚比较凉爽，天气较湿润，多雨，建议随身携带外套和雨具。墨尔本国际喜剧节、维珍墨尔本时装节、墨尔本美食美酒节都会在这期间举办，我们将协助您对接当地各项活动服务商。';
    case '6月 ~ 8月':
      return '这时的墨尔本是冬季，冬季湿润、多雨，但城市中几乎不会下雪，建议穿保暖的衣物，随身携带雨具。墨尔本国际电影节在每年8月举办，我们将协助您对接当地各项活动服务商。';
    case '9月 ~ 11月':
      return '这时的墨尔本是春季，白天温度适中，早晚比较凉爽，天气较湿润，多雨，建议随身携带外套和雨具。澳大利亚摩托车大奖赛、墨尔本杯赛马节将在这期间举办，我们将协助您对接当地各项活动服务商。';
    default:
      return '白天温度适中，早晚比较凉爽，天气较湿润，多雨，建议随身携带外套和雨具。澳大利亚摩托车大奖赛、墨尔本杯赛马节将在这期间举办，我们将协助您对接当地各项活动服务商。';
  }
};
const picQuestionThreeAnswer = () => {
  switch (localStorage.getItem('questionThree')) {
    default:
      return '除了前图所示的推荐景点，您还可以直接联系墨尔本会议局或关注官方微信获取更多路线推荐。无论是文化参观、休闲娱乐、美食购物还是探索城市周边，都会让您的行程充满乐趣，让您和您的成员满意而归。';
  }
};
const picQuestionFourAnswer = () => {
  switch (localStorage.getItem('questionFive')) {
    case '国际连锁酒店':
      return '能为您提供服务周全的身心放松之旅，闻名全球的泛太平洋酒店、皇冠酒店、朗庭酒店等都屹立在墨尔本市中心，还有其他知名酒店供您选择，扫码关注并联系我们，墨尔本会议局为您量身选择居住之所。';
    case '精品旅馆':
      return '能在节约您成本的同时，为您的宾客提供最佳居住体验。墨尔本的精品旅馆分布在墨尔本各地，位于市中心的美居酒店、位于查德斯通购物中心的索菲特美憬阁酒店和位于Albert Park的铂尔曼酒店都是您的首选。扫码关注并联系我们，墨尔本会议局为您量身选择居住之所。';
    case '酒店式公寓':
      return '能为您的宾客提供像家一样的舒适温馨，如墨尔本市区的宜必思酒店公寓、Adina酒店公寓等。扫码关注并联系我们，墨尔本会议局为您量身选择居住之所。';
    case '经济型酒店':
      return '除了能为您的旅行活动最大程度节省成本外，便捷的地理位置能让您的宾客随时走出酒店，享受墨尔本的城市风光。位于墨尔本滨海区及南岸的Travelodge酒店是经济型首选。扫码关注并联系我们，墨尔本会议局为您量身选择居住之所。';
    default:
      return '扫码并联系我们，墨尔本会议局为您量身选择居住之所。';
  }
};
const picQuestionFiveAnswer = () => {
  switch (localStorage.getItem('questionSix')) {
    case '在高档餐厅就餐':
      return '高档餐厅集中在墨尔本市中心和周边，有名的餐饮有Vue de monde，Attica， Amaru及Ishizuka等。我们可以根据您的需求，为您定制餐饮体验。';
    case '吃遍本地美食':
      return '墨尔本以咖啡文化和早午餐闻名，优质的咖啡小馆散布在墨尔本各个区域，如St Ali、Patricia等。我们将根据您的需求，为您找寻行程附近的本地美食。';
    case '去远郊品鉴乡村特色':
      return '墨尔本的葡萄酒庄世界闻名，市区附近就可以体验到浓厚的酒乡文化，每处都有独树一帜的风味与体验，例如罗富酒庄等。我们可以根据您的需求，为您定制独特的体验。';
    case '参加精品料理课程':
      return '墨尔本有诸多带有本地特色的料理课程，例如Cumulus Inc.的主厨课程及烘焙课程等。我们可以根据您的需求，为您定制独特的体验。';
    default:
      return '我们可以根据您的需求，为您定制独特的体验。';
  }
};
const questionOneKey = () => {
  switch (localStorage.getItem('questionOne')) {
    case '100 以上':
      return '100人以上';
    default:
      return localStorage.getItem('questionOne') + '人';
  }
};
export default class SaveResult extends Vue {
  data() {
    return {
      picQuestionOne: questionOneKey(),
      picQuestionTwo: localStorage.getItem('questionTwo'),
      picQuestionThree: localStorage.getItem('questionThree'),
      picQuestionFive: localStorage.getItem('questionFive'),
      picQuestionSix: localStorage.getItem('questionSix'),
      picQuestionOneAnswer: picQuestionOneAnswer(),
      picQuestionTwoAnswer: picQuestionTwoAnswer(),
      picQuestionThreeAnswer: picQuestionThreeAnswer(),
      picQuestionFourAnswer: picQuestionFourAnswer(),
      picQuestionFiveAnswer: picQuestionFiveAnswer(),
      mapId: localStorage.getItem('questionFour'),
      loading: true,
    };
  }
  monitorLoading() {
    console.log('load');
  }
  mounted() {
    this.$nextTick(function() {
      console.log('createThumbnail');
      // 仅在渲染整个视图之后运行的代码
      document.querySelector('.map-img').onload = () => {
        document.querySelector('html').style.overflowY = 'auto';
        document.querySelector('body').style.overflowY = 'auto';
        document.querySelector('#page9').style.height = 'auto';
        const el = document.querySelector('#page9');
        console.log('createThumbnail', el);
        el.classList.contains('hidden') && el.classList.remove('hidden');
        el &&
          html2canvas(el).then((canvas) => {
            this.loading = false;
            const img = new Image();
            img.style.display = 'block';
            // 将 canvas 导出成 base64
            img.src = canvas.toDataURL('image/jpeg');
            img.classList.add('long-img');
            console.log('url', img.src);
            // 添加图片到预览
            document.querySelector('#app').append(img);
            document.querySelector('#page9').classList.add('hidden');
            // el.style.display = 'none'
          });
      };
    });
  }
}
</script>
<style>
.hidden {
  display: none;
}
.list-wider {
  width: 75vw;
  margin-top: 280px;
}
#trip-info {
  background-image: url(/img/文字背景2.309cb24f.png);
  background-position-y: bottom;
  background-size: cover;
}
#contact-info-section {
  background-image: url(/img/文字背景1.cbfe9ea6.png);
  background-position-y: bottom;
  background-size: cover;
  padding: 10px 25px;
  margin-top: -5px;
}
#page9 .section-header {
  width: 100vw;
  padding: 16px 20px;
  box-sizing: border-box;
}
#page9 h2 {
  font-size: 25px;
  margin: 0;
  padding: 1rem;
}
#page9 p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  text-align: left;
  margin-block-start: 1rem;
  margin-block-end: 5px;
}
#page9 p.center {
  text-align: center;
  padding-bottom: 30px;
}
#page9 .question-list-item {
  padding-left: 5px;
  font-size: 28px;
}
#page9 .map-img {
  width: 100vw;
}
#page9 .qr-code-img {
  width: 30vw;
}
#page9 .q-next-page a {
  font-size: 25px;
  margin-right: 0px;
}
#preview {
  position: absolute;
  top: 0;
  z-index: 500;
}
#preview img {
  width: 100vw;
}
#page9 {
  background-size: cover;
}
.long-img {
  width: 100vw;
}
.loading-info {
  font-family: 'ZiZhiQuXiMaiTi';
  text-align: center;
}
/* #page6 .section-header h2{
        font-size: 31px;
    } */
.samller {
  font-size: 12px !important;
}
#page9 a {
  text-decoration: underline;
}
.contact-info {
  margin: 40px 0;
}
</style>
