<template>
  <div
    id="page8"
    class="pages"
    :class="{ 'map-bg1': selectedMap === '休闲观光', 'map-bg2': selectedMap === '文化参观', 'map-bg3': selectedMap === '美食购物','map-bg4': selectedMap === '去城市周边探索自然及野生动物' }"
  >
    <div v-if="selectedMap === '休闲观光'">
      <ImageContainer
        x="23"
        y="18"
        width="23"
        src="地图A-野生动物园.png"
        header="华勒比野生动物园：日落野外探游+主题晚宴"
        msg="
黄昏时分，让您的宾客在华勒比野生动物园（Werribee Open Range Zoo）闭园之后，悠然享受非洲式野外探险的无穷乐趣，在创造难忘回忆的同时，为保护濒危动物出一份力。行程包含：热带稀树草原导览野外探险游；非洲鼓+歌舞表演；在Meerkat Bistro餐厅举办非洲风晚宴。"
      />
      <ImageContainer
        x="51"
        y="75"
        width="22"
        src="地图A-哈雷摩托游.png"
        header="哈雷摩托游"
        msg="
        参加哈雷摩托游览项目（Harley Motorbike Tour），在海滨公路Beach Road领略菲利普港湾的著名胜景。"
      />
      <ImageContainer
        x="54"
        y="64"
        width="23"
        src="地图A-皇家植物园.png"
        header="皇家植物园"
        msg="
        自然美丽的景观设计，大受欢迎的原住民遗产导览游（Aboriginal Heritage Walk），都让皇家植物园（Royal Botanic Gardens）成为了值得一去的游览地点。在原住民向导人员的带领下，体验传统的烟熏祛邪仪式，了解原住民作为传统土地所有者的历史、文化，以及他们与土地之间的纽带。湖上泛舟也是非常舒适的选择，可以观赏到美丽的城市天际线。园内还有许多自然景观点，非常适合团体合影留念。"
      />
      <ImageContainer
        x="41"
        y="39"
        width="22"
        src="地图A-观景摩天轮.png"
        header="“墨尔本之星”观景摩天轮"
        msg="
        想体验一下“高空下午茶会”吗？墨尔本之星（Melbourne Star）不仅是全球四大巨型观景摩天轮之一，也是南半球唯一的巨型观景摩天轮。这里全年开放，为顾客奉上全新的墨尔本高空绝美景致。墨尔本之星共有21个轿厢，空间宽敞，空调控温，每间可容纳20名宾客，可自由走动，舒适惬意。每轮时长30分钟。欢迎预定包厢，享受独一无二的摩天轮观景+餐饮体验。"
      />
      <ImageContainer
        x="29"
        y="58.5"
        width="22"
        src="地图A-河滨休闲餐厅.png"
        header="亚拉河滨：休闲餐厅"
        msg="
西班牙加泰罗尼亚风格的酒吧餐厅Boho South Wharf，既有亚拉河河滨生活的闲适，又有丰富的美酒美食选择，如欧式冷餐拼盘、腌橄榄，还有很多“好吃到有罪恶感”的轻食小吃。"
      />
      <ImageContainer
        x="53"
        y="54"
        width="20"
        src="地图A-皮艇游.png"
        header="墨尔本河上皮艇游"
        msg="
参加墨尔本皮艇游（Melbourne City Kayak Tour），避开熙攘人群，以最闲适的方式观赏墨尔本的各个标志景点。出发之前，导游将进行简短的安全事宜和划艇培训，确保宾客安全。皮艇将顺流而下，穿过王子大桥，前往市中心方向。行程当中，导游还会为宾客讲解许多旧码头的趣闻。"
      />
    </div>
<div v-if="selectedMap === '文化参观'">
      <ImageContainer
        x="11"
        y="53"
        src="地图B-涂鸦游览.png"
        header="墨尔本涂鸦艺术游览"
        msg="
        墨尔本街头的涂鸦艺术举世闻名，历年来吸引了大量游客前来游览。参加涂鸦艺术导览活动，在本地导游的带领下，穿行众多小巷，发现艺术珍宝，感受墨尔本著名的街头艺术氛围，参观墨尔本许多历史小巷和历史古建，发掘隐藏的艺廊、著名艺术家涂鸦作品、雕塑艺术及现代艺术品。墨尔本街头的艺术随时都会变化，快来跟随专业艺术家的脚步，从高端艺廊到街头涂鸦，感受墨尔本的无穷魅力。"
      />
      <ImageContainer
        x="28"
        y="12"
        width="23"
        src="标签B-州立图书馆.png"
        header="维多利亚州立图书馆"
        msg="
维多利亚州立图书馆（State Library Victoria）拥有多个独特的活动举办场地，其中4处经过近期翻新，重返昔日荣光，兼具现代元素。图书馆中央的伊恩波特女王大厅（Ian Potter Queen’s Hall），加上各处会议中心、休憩场地，足以满足一切活动需求。如欲洽谈图书馆场地租用事宜，请联系专属活动合作方Showtime Event Group。"
      />
      <ImageContainer
        x="25"
        y="66"
        width="23"
        src="地图B-移动影像中心.png"
        header="澳大利亚移动影像中心"
        msg="澳大利亚移动影像中心（ACMI）是澳大利亚著名的影视博物场馆，在全世界同类场馆中有着开创先河的地位，不仅展出澳大利亚经典影视文物，还会举办许多主题展览活动，观众还可体验一下当影视工作者的滋味，制作自己的短片！这里活动丰富多彩，有兴趣班、论坛、展览、影展等等，帮助观众领略电影、电视、数码等不同影视文化的丰富和创意。ACMI每年都会举办多种多样的活动，其中不乏荣获大奖的展览，还有电影放映、影视节庆、现场表演、创意工坊、中小学假日专题活动及馆藏资源展出，满足不同需求和喜好，帮助观众走进奇妙的影视世界。"
      />
      <ImageContainer
        x="60"
        y="84"
        width="23"
        src="地图B-维州国立美术馆.png"
        header="维多利亚州国立美术馆"
        msg="
       维多利亚州国立美术馆（NGV）是澳大利亚历史最久、最知名的公立艺术场馆，举办的展览常常引起轰动，馆藏也堪称本区域之最。NGV的国际藏品丰富，展品来自欧洲、亚洲、大洋洲、美洲等世界各地，建筑本身经过重新再设计，与南半球最大馆藏相匹配，堪称墨尔本必看的标志景观。博物馆餐厅Garden Restaurant可预定活动包场，宾客可享受Grollo Equiset Garden雕塑花园的美丽景致。如果活动需要，NGV还可安排团体私享的馆藏导览参观。"
      />
      <ImageContainer
        x="47"
        y="41"
        src="地图B-论坛剧院.png"
        header="墨尔本论坛剧场：原住民正式欢迎晚宴"
        msg="
        独特的建筑风格，华美的舞台装潢，都让墨尔本论坛剧场（Forum Melbourne）成为了墨尔本最知名的剧场之一。始建于1929年，容纳人数为当年澳大利亚之最，现在也是墨尔本最独特、最适合现场演奏的剧院之一，每年接待众多乐团、歌手、喜剧演员，同时是许多文艺节庆的指定参与场馆。此外还可作为活动场地租出，适合鸡尾酒会、发布会、时装展等各类活动。"
      />
    </div>
    <div v-if="selectedMap === '美食购物'">
      <ImageContainer
        x="31"
        y="72.4"
        width="27"
        src="地图C-品味咖啡.png"
        header="St Ali Coffee Roasters：品味咖啡"
        msg="
作为墨尔本历史最久的自焙咖啡店，St Ali Coffee Roasters的手磨咖啡会让您的一天有一个美味的开端。店里提供咖啡试尝，快来亲自品评一下，墨尔本的咖啡是否真的担得起全球第一的美名！"
      />
      <ImageContainer
        x="63"
        y="86.5"
        width="32"
        src="地图C-小巷主题晚餐.png"
        header="铂尔曼酒店：小巷主题晚餐"
        msg="
在阿尔伯特公园的铂尔曼酒店（Pullman Hotel）背后，有一条名为“莉齐”的小巷（Lizzie’s Lane）。它的名称来自于英国女王伊丽莎白的小名，因为这里有一幅墨尔本街头艺术家创作的色彩明亮的大型女王画像。这里气氛轻松，绿意盈盈，通过铂尔曼酒店的各种墨尔本小巷主题的鸡尾酒会或活动，小巷可按需求进行妆点，为宾客带来欢乐时光。"
      />
      <ImageContainer
        x="28"
        y="30.5"
        width="28"
        src="地图C-厨艺学习.png"
        header="Cumulus Inc.：美味午餐+学习"
        msg="
到墨尔本标志性的现代餐厅之一Cumulus Inc.，在享受美味的当地特色午餐之余，向行政厨师长学习厨艺奥秘。"
      />
      <ImageContainer
        x="40"
        y="46.5"
        width="36"
        src="地图C-时尚之都购物与美食.png"
        msgTwo='查斯顿购物中心旁边的美憬阁酒店（Hotel Chadstone Melbourne MGallery）内，坐落着美味的Altus餐厅。这里有着饱含匠心的菜肴，宾客透过落地大窗，还可尽情歆享墨尔本的美丽天际之景。'
        headerTwo='美憬阁酒店：美味餐厅'
        header="查斯顿购物中心：高端时尚之都"
        msg="查斯顿（Chadstone）是澳大利亚最大的购物中心，也是世界闻名的时尚与娱乐之地，不论本地还是国际游客，都会来此享受高端购物之趣。这里有超过550间店面，设计师精品店众多，并有32家奢侈品牌入驻。"
      />
      <ImageContainer
        x="6"
        y="58.5"
        width="30"
        src="地图C-极致高空晚宴.png"
        header="Eureka 89：极致高空晚宴"
        msg="Eureka 89是墨尔本最为高端的活动场地和高级晚宴场所之一，位于尤利卡大厦（Eureka Tower）最高层。这里360度落地窗景致绝美，宾客将在墨尔本灿烂的落日余晖之中，享受精致美味的菜肴和美酒。"
      />
      <ImageContainer
        x="27"
        y="8"
        width="38"
        src="地图C-维多利亚女皇市场.png"
        header="维多利亚女皇市场"
        msg="参加Ultimate Foodie Tour导览活动，在两个小时之内游览维多利亚女皇市场（Queen Victoria Market），品尝当地时鲜美食，探索本地特有食材，了解采购窍门，学习烹饪秘技，还可顺便听听市场的历史，结识许多可亲可爱的店家和摊贩。"
      />
    </div>
    <div v-if="selectedMap === '去城市周边探索自然及野生动物'">
      <ImageContainer
        x="24"
        y="20"
        width="28"
        src="地图D-亚拉谷热气球.png"
        header="亚拉谷：热气球之旅"
        msg="乘上Global Ballooning的热气球，在墨尔本或亚拉谷高空感受日出的震撼，一个小时的晨光飞行，俯瞰无数绿地花园，感受墨尔本“世界最宜居城市”的美名来源，或是享受世界著名葡萄酒产地——亚拉谷疗愈的葡萄园与牧场美景。飞行之后，还可为宾客安排一餐美味的5星级香槟早餐，升华整个体验。"
      />
      <ImageContainer
        x="8"
        y="86.5"
        width="26"
        src="地图D-企鹅归巢.png"
        header="企鹅归巢游客中心：晚餐"
        msg="
在新近翻修的企鹅归巢游客中心（Penguin Parade Visitor Centre）享用晚餐，品尝本地海鲜，同时享受大洋胜景。"
      />
      <ImageContainer
        x="42"
        y="7"
        width="24"
        src="地图D-罗富酒庄.png"
        header="罗富酒庄"
        msg="
罗富酒庄（Rochford Winery）拥有世界顶级设施，供应高端美食美酒，并配有专业的活动承办团队。多样的空间选择，不论团体大小均可满足需求。酒庄还提供奖励旅游活动和团建活动选择，包含混酿工坊、葡萄酒游戏、踩葡萄、品酒、滚酒桶、寻宝等等。"
      />
      <ImageContainer
        x="49"
        y="78.5"
        width="40"
        src="地图D-菲利普岛自然公园.png"
        header="菲利普岛自然公园"
        msg="欢迎前来菲利普岛自然公园（Phillip Island Nature Park），踏上一场滨海野生动物探索之旅，还可安排私享/VIP观赏企鹅归巢盛景，为宾客带来墨尔本独有的难忘感受。"
      />
      <ImageContainer
        x="67"
        y="33"
        width="24"
        src="地图D-雷纳果园.png"
        header="雷纳果园"
        msg="雷纳果园（Rayner’s Orchard）是一座小型精品果园，种植有450余种水果，是团建的绝佳之地，全年开放，可选活动包括水果采摘、水果品尝大冒险、果酱制作、罐头工坊等等众多选择。"
      />
      
    </div>
    <div class="q-next-page next-to-result">
      <router-link to="/saveResult">进一步定制我的行程</router-link>
    </div>
  </div>
</template>
   
<script lang="ts">
// import { useRoute } from 'vue-router';
import { Options, Vue } from 'vue-class-component';
// import { computed } from 'vue';
import ImageContainer from '../components/ImageContainer.vue';
// import { getQueryByName } from './../utils';
@Options({
  components: {
    ImageContainer,
  },
})
export default class Cutomize extends Vue {
  data() {
    // const id = getQueryByName('selected');
    const id = localStorage.getItem('questionFour');

    console.log('ssss', id);
    return {
      selectedMap: id,
    };
  }
}
</script>
<style>
.next-to-result {
  bottom: 0vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
}
.map-bg1 {
  background-image: url('./../assets/路线图页-地图A.png');
}
.map-bg2 {
  background-image: url('./../assets/路线图页-地图B.png');
}
.map-bg3 {
  background-image: url('./../assets/路线图页-地图C.png');
}
.map-bg4 {
  background-image: url('./../assets/路线图页-地图D.png');
}
</style>

