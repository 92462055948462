<template>
    <div
        id="page4"
        class="pages"
    >
        <div class="section-header">
            <h2>您的会奖旅行会持续几天?</h2>
        </div>
        <div class="question-list">
            <!-- <div class="question-list-item"><router-link to="/question-four">A:  1 ~ 3 天</router-link></div>
            <div class="question-list-item"><router-link to="/question-four">B:  4 ~ 6 天</router-link></div>
            <div class="question-list-item"><router-link to="/question-four">C:  7 ~ 10 天</router-link></div>
            <div class="question-list-item"><router-link to="/question-four">D:  还不确定</router-link></div> -->
            <div class="question-list-item">
                <input
                    type="radio"
                    id="A"
                    value="1 ~ 3 天"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="A">A: 1 ~ 3 天<span v-if="picked  === '1 ~ 3 天'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="B"
                    value="4 ~ 6 天"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="B">B: 4 ~ 6 天 <span v-if="picked  === '4 ~ 6 天'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="C"
                    value="7 ~ 10 天"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="C">C: 7 ~ 10 天 <span v-if="picked  === '7 ~ 10 天'">✓</span></label>
            </div>
            <div class="question-list-item">
                <input
                    type="radio"
                    id="D"
                    value="还不确定"
                    v-model="picked"
                    v-on:change="toggleSelection"
                >
                <label for="D">D: 还不确定 <span v-if="picked  === '还不确定'">✓</span></label>
            </div>
            <div class="q-next-page">
                <router-link to="/question-two">上一题</router-link>
                <router-link
                    to="/question-four"
                    v-if="picked"
                >下一题</router-link>
            </div>
        </div>

    </div>
</template>
   
<script lang="ts">
import { Vue } from 'vue-class-component';
// import ImageContainer from '../components/ImageContainer.vue';
// @Options({
//   components: {
//     ImageContainer,
//   },
// })
export default class QuestionThree extends Vue {
  private picked = '';

  get label() {
    return this.picked;
  }
  data() {
    return {
      picked: '',
    };
  }
  toggleSelection() {
    console.log('Hello World!', this.picked);
    localStorage.setItem('questionThree', this.picked);
  }
}
</script>
<style>
#page4 {
  background-image: url('./../assets/bg_image_3.png');
}
</style>

