<template>
  <div
    id="page2"
    class="pages"
  >
    <!-- <HelloWorld msg="在下一次会奖旅行中，您预计会有几个人一同出行" /> -->
    <div class="section-header">
      <h2>在下一次会奖旅行中，您预计会有几个人一同出行</h2>
    </div>
    <div class="question-list">
      <!-- <div class="question-list-item"><router-link to="/question-two">A: 5 ~ 20</router-link></div>
            <div class="question-list-item"><router-link to="/question-two">B: 20 ~ 50</router-link></div>
            <div class="question-list-item"><router-link to="/question-two">C: 50 ~ 100</router-link></div>
            <div class="question-list-item"><router-link to="/question-two">D: 100 以上</router-link></div> -->
      <div class="question-list-item">
        <input
          type="radio"
          id="A"
          value="5 ~ 20"
          v-model="picked"
          v-on:change="toggleSelection"
        >
        <label for="A">A: 5 ~ 20 <span v-if="picked  === '5 ~ 20'">✓</span></label>
      </div>
      <div class="question-list-item">
        <input
          type="radio"
          id="B"
          value="20 ~ 50"
          v-model="picked"
          v-on:change="toggleSelection"
        >
        <label for="B">B: 20 ~ 50 <span v-if="picked  === '20 ~ 50'">✓</span></label>
      </div>
      <div class="question-list-item">
        <input
          type="radio"
          id="C"
          value="50 ~ 100"
          v-model="picked"
          v-on:change="toggleSelection"
        >
        <label for="C">C: 50 ~ 100 <span v-if="picked  === '50 ~ 100'">✓</span></label>
      </div>
      <div class="question-list-item">
        <input
          type="radio"
          id="D"
          value="100 以上"
          v-model="picked"
          v-on:change="toggleSelection"
        >
        <label for="D">D: 100 以上 <span v-if="picked  === '100 以上'">✓</span></label>
      </div>
      <div class="q-next-page" v-if="picked">
            <router-link to="/question-two">下一题</router-link>
          </div>
    </div>
    
  </div>
</template>
<script lang="ts">
// import { reactive } from 'vue'
import { Vue } from 'vue-class-component';
// import ImageContainer from '../components/ImageContainer.vue';
// @Options({
//  picked: String
// })
export default class QuestionOne extends Vue {
  private picked = '';

  get label() {
    return this.picked;
  }
  data() {
    return {
      picked: '',
    };
  }
  toggleSelection() {
    console.log('Hello World!', this.picked);
    localStorage.setItem('questionOne', this.picked);
  }
}
</script>

<style>
.q-next-page {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
.section-header h2 {
  font-size: 25px;
}
.q-next-page a {
  color: #2c3e50;
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  line-height: 11.467vw;
  margin-bottom: 5.333vw;
  height: 11.467vw;
  /* margin-right: 6.667vw; */
  min-width: 20vw;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVAAAAClCAYAAAAKwKXHAAAE6ElEQVR4nO3by24b5x3G4fcbjk6WD3WaIAjqbtI76P3fR5EsAzgI0KBNY7eWaJLThd1FgEi0XkumKj8PwN3Mh79AzI9z0liWZUpynGROMnK94/cfDuMoyfNDD/GA/Zrk4tBDPBBvkmwPPcQdWidZz0nOk823yfTnJCfX77P7QzKe592BzCe1JBknyfjq0JM8UEuy/DPJfw49yMMwfkzy+tBT3I1pm2y+T+bv5iRnyfSXZPw1yePrd1w9SpYnSVafYEp+YyTJlL0/cvTGN3nYZ02f0Pg2yb8PPcXdWNbJeJPk+zm5OEqOv0jyIhnP9uw5kiGeh7XvNgs9t6duzXKSjN2hp7gjF8nqaZLpf/c9p2TMcWnO582P060ZqzzcK9Vt3v9t04EHAfi/JaAAJQEFKM0fttny/gPwORm57tb4BwR0lyWX2Xq7A/iMTBmZcpR3z9Z/P6J7Arpkvfk1v7x5mYv1L9ktD/WtBIDfWk1znp69yJPTbzKN30/l3oBebl7nH69+yL/evMx2t7mDMQHun6P5LGOc5vzk60xXXMXvvYRfll22u3Xebi+z27297RkB7qUxpux2m1z3/MdTeICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAAJQEFKAkoQElAAUoCClASUICSgAKUBBSgJKAApXnfBmOsslqd5Gh+lN3u7aeYCeDgjuazTKs5I+PKbfYEdMrRfJ7nj/+Us+NH2S7bWx4R4H6ap+M8Of1jxrj6Qn3vGejx6jzPzl7k8cmXWbLc6oAA99UYqxytzjPG6spt9gZ0GnNO5mfJ8vRWhwO498b4mEv4JHm/wNVrAHyWPIUHKAkoQElAAUpzkl2St0kuk1zcwprHccf0PvFd3C2vpvQuDz1A6SLJJknm5HSd5Kdk912ynH/82uPrJI/iwL0v5mQ5zQc9MKRwkYzLCOkNLdtkeZmMzaEnubllk4yfk9VuTvI6yd+S5e/J5hYOsvFVMp1HQO+LVbJ7nkxf+EruwrJONi+TlX/Tu5HtNslPSdaHnuTm5l2y+jHJdizLMvLu7OTqt0VvuHryapU8uaXl+DivRrKak2lOTg89zEO0JK8vk8fOQG9mSX7eJF/uDj1IaTPG2PwXiCaZtFOTD2sAAAAASUVORK5CYII=)
    no-repeat;
  background-size: 100% 100%;
  font-size: 29px;
  text-decoration: none;
}
#page2 {
  background-image: url('./../assets/bg_image_1.png');
}
.question-list {
  width: 65vw;
  text-align: left;
  /* margin: 30vh auto 0; */
  position:absolute;
  bottom: 1vh;
  left: 50%;
  transform: translateX(-50%);
}
.question-list-item {
  /* padding-left: 20px; */
  line-height: 11.467vw;
  margin-bottom: 5.333vw;
  height: 11.467vw;
  /* margin-right: 6.667vw; */
  font-size: 31px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAxQAAAB5CAYAAACgAWeIAAADE0lEQVR4nO3aMWoUUBSG0ZvMaIzERhCs0gS0EsTeDWQFbtSNJDuwsQ0iMlHGcQNxxA+TmZFz2vuKv/3gHW02m8uZ9asBAAD4O6vlzPrNzOL9rpcAAAAH5+Z41wsAAIDDJSgAAIBMUAAAAJmgAAAAsuWfHvycr7Oe7w+xBQAA2EOLeTzHc3bnbWtQbGY1V58+zpdvn+9lGAAAsP/Onr6cd+cf7rz58gQAAGSCAgAAyAQFAACQCQoAACATFAAAQCYoAACATFAAAACZoAAAADJBAQAAZIICAADIBAUAAJAJCgAAIBMUAABAJigAAIBMUAAAAJmgAAAAMkEBAABkggIAAMgEBQAAkAkKAAAgExQAAEAmKAAAgExQAAAAmaAAAAAyQQEAAGSCAgAAyAQFAACQCQoAACATFAAAQCYoAACATFAAAACZoAAAADJBAQAAZIICAADIBAUAAJAJCgAAIBMUAABAJigAAIBMUAAAAJmgAAAAMkEBAABkggIAAMgEBQAAkAkKAAAgExQAAEAmKAAAgExQAAAAmaAAAAAyQQEAAGSCAgAAyAQFAACQCQoAACATFAAAQCYoAACATFAAAACZoAAAADJBAQAAZIICAADIBAUAAJAJCgAAIBMUAABAJigAAIBMUAAAAJmgAAAAMkEBAABkggIAAMgEBQAAkAkKAAAgExQAAEAmKAAAgExQAAAAmaAAAAAyQQEAAGSCAgAAyAQFAACQCQoAACATFAAAQCYoAACATFAAAACZoAAAADJBAQAAZIICAADIBAUAAJAJCgAAIBMUAABAJigAAIBMUAAAAJmgAAAAMkEBAABkggIAAMgEBQAAkAkKAAAgExQAAEAmKAAAgExQAAAAmaAAAAAyQQEAAGSCAgAAyAQFAACQCQoAACATFAAAQCYoAACATFAAAACZoAAAADJBAQAAZIICAADIltuOR/Nkzl+8ndsfFw+1BwAA2DMnj5799rY1KGZmnp++njn9p3sAAID/hC9PAABAJigAAIBMUAAAAJmgAAAAsuXM4npmfbvrIQAAwMFZ/QLi/xtqvJwloAAAAABJRU5ErkJggg==)
    no-repeat;
  background-size: contain;
}
.question-list-item input {
  display: none;
}
.question-list-item label {
  width: 100%;
  display: inline-block;
  padding-left: 20px;
}
.question-list-item a {
  text-decoration: none;
  color: #2c3e50;
}
</style>
