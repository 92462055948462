import request from '@/plugins/request';

// function NetReq (data, tranCode) {
//     util.cookies.set('tranCode', tranCode);
//     data.usrId = util.cookies.get('usrId')
//     data = {
//         head: transcode.HEAD(tranCode, util.cookies.get('usrId'), util.cookies.get('tokenId')),
//         body: data
//     }
//     return request({
//         url: 'world',
//         method: 'post',
//         data
//     }).then();
// }
export function NetWork (data, tranCode) {
    console.log('没进来？')
    return new Promise((resolve, reject) => {
        // 开始请求登录接口
        data = {
            head: {
                tranCode: tranCode,
                deviceId: '临时'
            },
            body: data
        }
        request({
            url: 'world',
            method: 'post',
            data
        })
            .then(async res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            })
    })
}
