<template>
    <div>
        <!-- <div v-on:click="isHidden = !isHidden" :style="{ left: x + 'vw', top: y + 'vh'}" class="poi">在高档餐厅就餐</div> -->
        <img alt="poi-img" v-on:click="isHidden = !isHidden" :style="{ left: x + 'vw', top: y + 'vh', width: width + 'vw'}" class="poi" v-bind:src="require('../assets/'+src)" />
        <div v-if="!isHidden" class="poi-info">
          <span v-on:click="isHidden = !isHidden" class="close">X</span>
          <div class="modal">
            <p>{{header}}</p>
            <p>
            {{msg}}
          </p>
          <p>{{headerTwo}}</p>
            <p>
            {{msgTwo}}
          </p>
          </div>
          
        </div>
    </div>
</template>
<script lang="ts">
// import { reactive, computed } from 'vue';
// import { computed } from 'vue';

import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    src: String,
    msg: String,
    x: String,
    y: String,
    width: String,
    header: String,
    msgTwo: String,
    headerTwo: String
  },
})
export default class ImageContainer extends Vue {
  src!: string;
//   setup() {
//     function getImgUrl(src: string) :string {
//         return require('../assets/'+src)
//     }
//     return {
//       getImgUrl,
//     };
//   }
  
data() {
    return {
      isHidden: true
    };
  }
}

//   export default {
//     setup() {
//       const state = reactive({
//         count: 0,
//         double: computed(() => state.count * 2),
//       })

//       function increment() {
//         state.count++
//       }

//       return {
//         state,
//         increment,
//       }
//     },
//   }
</script>
<style>
.poi {
    position: absolute;
    width: 20vw;
    /* animation: shining 0.8s linear 1s 1 both; */
    animation: 2s linear 1s infinite alternate shining-tag;
}
.poi-info {
      position: absolute;
    background: #000000cf;
    z-index: 200;
    height: 100vh;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    text-align: left;
}
.close {
  position: fixed;
  right:  5vw;
  top: 4vh;
  font-size: 22px;
}
.modal {
  padding: 20vw 5vw
}
</style>
