
import { Vue } from 'vue-class-component';
import vhCheck from 'vh-check';
vhCheck('browser-address-bar');
console.log('ss', window.innerHeight);
if (document.documentElement.clientWidth > 766) {
  alert('为了更好的效果，请在手机端打开')
}

// import HelloWorld from "../components/HelloWorld.vue";
// @Options({
//     components: {
//         HelloWorld,
//     },
// })
export default class Home extends Vue {
    toNext() {
      this.$router.push({path:'/question-one'})
  }
}
