
// import { reactive, computed } from 'vue';
// import { computed } from 'vue';

import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    src: String,
    msg: String,
    x: String,
    y: String,
    width: String,
    header: String,
    msgTwo: String,
    headerTwo: String
  },
})
export default class ImageContainer extends Vue {
  src!: string;
//   setup() {
//     function getImgUrl(src: string) :string {
//         return require('../assets/'+src)
//     }
//     return {
//       getImgUrl,
//     };
//   }
  
data() {
    return {
      isHidden: true
    };
  }
}

//   export default {
//     setup() {
//       const state = reactive({
//         count: 0,
//         double: computed(() => state.count * 2),
//       })

//       function increment() {
//         state.count++
//       }

//       return {
//         state,
//         increment,
//       }
//     },
//   }
