
import { Vue } from 'vue-class-component';
// import ImageContainer from '../components/ImageContainer.vue';
// @Options({
//   components: {
//     ImageContainer,
//   },
// })
export default class QuestionFive extends Vue {
  private picked = '';

  get label() {
    return this.picked;
  }
  data() {
    return {
      picked: '',
    };
  }
  toggleSelection() {
    console.log('Hello World!', this.picked);
    localStorage.setItem('questionFive', this.picked);
  }
}
